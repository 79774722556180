<template>
  <b-card>
    <form-component :sale-representative="saleRepresentative" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/sale-representatives/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/sale-representatives';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      saleRepresentative: {
        id: '',
        county_id: '',
        name: '',
        surname: '',
        phone: '',
        email: '',
      },
    };
  },

  methods: {
    async onSubmit(form) {
      try {
        await create(form);
        this.showSuccessNotification('Dane zapisane', 'Przedstawiciel handlowy został dodany.');
        this.$router.push({ name: 'sale-representatives-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania przedstawiciela handlowego. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
